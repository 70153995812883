import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"

import Menu from "../components/menus/Menu"

export default function Food() {
  return (
    <Layout pageTitle="drinks">
      <Page>
        {/* <iframe
          src="https://business.untappd.com/embeds/iframes/30566/117794"
          frameborder="0"
          height="4300"
          width="100%"
        ></iframe> */}
        {/* <Menu poweredListID="powered-list-9" mode="allInOnce"></Menu> */}
        <Title>Our Drinks</Title>
        <Menu poweredListID={"powered-list-1"} mode={"cellsThenAllInOnce"} />
      </Page>
    </Layout>
  )
}

const Title = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin: 3rem 0;
  color: #111;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`

const Page = styled.section`
  padding: 1rem;
  background-color: ${props =>
    props.theme.light ? props.theme.light : "black"};
  font-family: ${props =>
    props.theme.fonts ? props.theme.fonts.bodyFont : "Arial, sans-serif"};
  }
`
// add to styled component if there is no hero
// padding-top: ${props =>
//   props.theme.custom ? props.theme.custom.navHeight : "150px"};
// @media (min-width: 1024px) {
// padding-top: ${props =>
//   props.theme.custom ? props.theme.custom.navHeightDesktop : "150px"};

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
